// Here you can add other styles

.publicPageListItem {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    color: black;
}

.publicPageTableItem {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    color: black;
    text-align: left;
    background-color: #f9fafb;
}
.publicPageTableHeader {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    font-style: bold;
    background-color: #0570b7;
    color: white;
}

.publicPageTableCourseHeader {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    background-color: #0570b7;
    color: black;
}

.publicPageTableItemRight {
    text-align: right !important;
}

.publicPageTableItemCenter {
    text-align: center !important;
}

.publicPageTableItemLeft {
    text-align: left !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #0570b7 !important;
}

@media screen and (max-width: 450px) {
    .publicPageTableHeader {
        font-size: 0.55em;
    }
    .publicPageTableItem {
        font-size: 0.55rem;
    }
}

.c-header {
    background-color: #0570b7;
    color: white;
}

.c-header-nav-link {
    color: white;
}

.c-header-nav {
    color: white;
}

.c-header .c-header-nav .c-header-nav-link,
.c-header .c-header-nav .c-header-nav-btn {
    color: white;
}


// .sx-react-calendar-wrapper {
//   width: 1200px;
//   max-width: 100vw;
//   height: 800px;
//   max-height: 90vh;
// }

:root {
    --sx-color-on-primary-container: #fff !important;
    --sx-color-primary-container: #589fcf !important;
    --sx-color-primary: #146899 !important; 

}